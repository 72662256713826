import React from 'react'
import Layout from '@/components/layout'
import { TestimonialSection } from '@/components/TestimonialSection'
import { PartnersSection } from '@/components/PartnersSection'
import { StopStartSection } from '@/components/StopStartSection'
import { Footer } from '@/components/Footer'
import WhatDoYouGainManager from '@/components/SolutionPages/ItManger/WhatDoYouGainManager'
import Benefits from '@/components/SolutionPages/Benefits'
import ItManagerProblemSolution from '@/components/SolutionPages/ItManger/ItManagerProblemSolution'
import SolutionItManagerHeader from '@/components/SolutionPages/ItManger/SolutionItManagerHeader'
import { graphql, useStaticQuery } from 'gatsby'

const ItManager: React.FC = () => {
  const {
    allContentfulSubPagesTitles: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSubPagesTitles {
        nodes {
          itManagerPage
        }
      }
    }
  `)

  const { itManagerPage } = nodes[0]
  return (
    <Layout title={itManagerPage}>
      <SolutionItManagerHeader />
      <ItManagerProblemSolution />
      {/*<TestimonialSection />*/}
      <PartnersSection />
      <WhatDoYouGainManager />
      <Benefits />
      <StopStartSection />
      <Footer />
    </Layout>
  )
}

export default ItManager
